@import "./colors";

@mixin TsRounded {
  border-radius: clamp(4rem, 10vw, 7.8125rem);

  @media (max-width: 767px) {
    border-radius: clamp(2rem, 5vw, 5rem);
  }
}

@mixin TsRoundedMd {
  border-radius: clamp(1.2rem, 3vw, 3rem);
}

@mixin TsRoundedSm {
  border-radius: clamp(1rem, 3vw, 2.5rem);
}

@mixin TsRoundedNotTopRight {
  border-top-right-radius: 0;
}

@mixin TsRoundedNotTopLeft {
  border-top-left-radius: 0;
}

@mixin TsRoundedNotBottomRight {
  border-bottom-right-radius: 0;
}

@mixin TsRoundedNotBottomLeft {
  border-bottom-left-radius: 0;
}

.ts-rounded {
  @include TsRounded;

  &-md {
    @include TsRoundedMd;
  }

  &-sm {
    @include TsRoundedSm;
  }

  &--not-top-right {
    @include TsRoundedNotTopRight;
  }

  &--not-top-left {
    @include TsRoundedNotTopLeft;
  }

  &--not-bottom-right {
    @include TsRoundedNotBottomRight;
  }

  &--not-bottom-left {
    @include TsRoundedNotBottomLeft;
  }
}

.ts-shadow {
  &-sm {
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  }
}

.text {
  &-primary {
    &-01 {
      color: $primary-01;
    }

    &-02 {
      color: $primary-02;
    }

    &-03 {
      color: $primary-03;
    }

    &-04 {
      color: $primary-04;
    }

    &-05 {
      color: $primary-05;
    }

    &-06 {
      color: $primary-06;
    }
  }

  &-gray {
    &-01 {
      color: $gray-01;
    }

    &-02 {
      color: $gray-02;
    }

    &-03 {
      color: $gray-03;
    }

    &-04 {
      color: $gray-04;
    }

    &-05 {
      color: $gray-05;
    }

    &-06 {
      color: $gray-06;
    }

    &-07 {
      color: $gray-07;
    }

    &-08 {
      color: $gray-08;
    }

    &-09 {
      color: $gray-09;
    }
  }
}

.ts-disabled {
  background-color: $gray-06 !important;
  color: white;
  box-shadow: none;
  border-color: $gray-06 !important;
}

// .mh-body-height-main {
//   min-height: calc(100vh - 407px);
// }
.mh-body-height {
  min-height: calc(100vh - 30.125rem);
}